<template>
  <div class="layout">
    <div class="header">
     
        <img src="../assets/logo-transparent-cropped.png" alt="" />
      
    </div>

    <div class="content">
      <div class="mobSidebar">
        <b-icon icon="filter-left" v-b-toggle.sidebar-backdrop></b-icon>

        <b-sidebar id="sidebar-backdrop" title="Menu" backdrop shadow>
          <div class="mobSideBarContent">
            <div class="sideItem">
              <router-link to="/profile">
                <p>
                  <img
                    src="../assets/img/dashboard/icons8-user-50.png"
                    alt=""
                  />
                  Profile
                </p>
              </router-link>
            </div>
            <div class="sideItem">
              <router-link to="/vaccine-history">
                <p>
                  <img src="../assets/img/dashboard/id-card.png" alt="" />
                  My Vaccine History
                </p>
              </router-link>
            </div>
            <div class="sideItem">
              <router-link to="/vaccine-card">
                <p>
                  <img src="../assets/img/dashboard/vaccine-card.svg" alt="" />
                  My Vaccine Card
                </p>
              </router-link>
            </div>
            <div class="sideItem">
              <router-link to="/qr-code">
                <p>
                  <img src="../assets/img/dashboard/qr-scan.png" alt="" />
                  My QR Code
                </p>
              </router-link>
            </div>
            <div class="sideItem">
              <router-link to="/health-information">
                <p>
                  <img
                    src="../assets/img/dashboard/icons8-health-book-50.png"
                    alt=""
                  />
                  Health Information
                </p>
              </router-link>
            </div>
            <div class="sideItem">
              <router-link to="/">
                <p>
                  <img
                    src="../assets/img/dashboard/flight-information.png"
                    alt=""
                  />
                  Travel Information
                </p>
              </router-link>
            </div>
          </div>
        </b-sidebar>
      </div>
      <div class="sideBar">
        <div class="sideItem">
          <router-link to="/profile">
            <p>
              <img src="../assets/img/dashboard/icons8-user-50.png" alt="" />
              Profile
            </p>
          </router-link>
        </div>
        <div class="sideItem">
          <router-link to="/vaccine-history">
            <p>
              <img src="../assets/img/dashboard/id-card.png" alt="" />
              My Vaccine History
            </p>
          </router-link>
        </div>
        <div class="sideItem">
          <router-link to="/vaccine-card">
            <p>
              <img src="../assets/img/dashboard/vaccine-card.svg" alt="" />
              My Vaccine Card
            </p>
          </router-link>
        </div>
        <div class="sideItem">
          <router-link to="/qr-code">
            <p>
              <img src="../assets/img/dashboard/qr-scan.png" alt="" />
              My QR Code
            </p>
          </router-link>
        </div>
        <div class="sideItem">
          <router-link to="/health-information">
            <p>
              <img
                src="../assets/img/dashboard/icons8-health-book-50.png"
                alt=""
              />
              Health Information
            </p>
          </router-link>
        </div>
        <div class="sideItem">
          <router-link to="/">
            <p>
              <img
                src="../assets/img/dashboard/flight-information.png"
                alt=""
              />
              Travel Information
            </p>
          </router-link>
        </div>
      </div>
      <div class="centerContent">
        <router-view> </router-view>
      </div>
    </div>

    <div class="footer">
      <p>Copyright JabComp Ⓒ {{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.layout {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 44px;

  .header {
    width: 100%;
    height: 100px;
    background: #005eaa33;
    text-align: center;
    position:relative;
    // padding: 15px;
    border-radius: 0 0 70px 70px;
    img {
      height: 100%;
      max-width: 90%;
      object-fit: contain;
    }
  }

  .content {
    height: 100%;
    width: 100%;

    .mobSidebar {
      margin: 20px 0 0 20px;
      display: none;

      .b-icon {
        font-size: 2rem;

        &:focus,
        &:active {
          border: 0 !important;
          outline: none !important;
        }
      }
    }

    .sideBar,
    .mobSideBarContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 25vw;
      max-width: 300px;
      min-width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 99;
      padding-top: 30vh;
      @media (max-width: 800px) {
        padding-top: 20vh;
      }

      .sideItem {
        width: 100%;
        padding: 15px 10px 15px 40px;
        box-shadow: 0px 10px 20px #00000029;
        border: 1px solid #005eaa33;
        border-radius: 0px 50px 50px 0px;

        &:hover {
          background: rgba(233, 231, 231, 0.5);
          cursor: pointer;
        }
        a{
          text-decoration:none;
        }

        p {
          margin-bottom: 0;
          color: #333333;

          img {
            height: 30px;
            max-width: 30px;
            margin-right: 20px;
          }
        }
      }
    }

    .centerContent {
      padding-left: 340px;
      padding-right: 50px;

      @media (max-width: 768px) {
        padding: 0;
      }
    }

    @media (max-width: 800px) {
      .mobSidebar {
        display: block;
      }
      .sideBar {
        display: none;
      }
      .centerContent {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #005eaa33;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px 20px 0 0;

    > p {
      margin: 0;
    }
  }
}
</style>